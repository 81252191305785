import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import AppImg from "../../components/atoms/Img/AppImg";
import logoAune from "../../assets/image/landingpage/aune-white.png";
import pacifico from "../../assets/image/landingpage/pacifico.png";
import mapaCol from "../../assets/image/landingpage/mapCop.png";
import user from "../../assets/image/landingpage/iconGov.png";
import lupa from "../../assets/image/landingpage/investigadores.png";
import plataforma from "../../assets/image/landingpage/plataforma.png";
import AppText from "../../components/atoms/Text/AppText";
import Header from "../../components/organisms/Header/Header";
import AppButton from "../../components/atoms/Buttons/AppButton";
// import Footer from "../../components/organisms/Footer/Footer";

export default function LandingPage() {
  const navigate = useNavigate();

  const onEnterPlatform = () => {
    navigate("/login");
  };
  const onRedirectRegister = () => {
    navigate("/register");
  };

  return (
    <>
      <Header isLanding />
      <section className="relative h-full flex flex-col items-center text-white ">
        {/* Background Image */}
        <div className="flex flex-col w-full p-4">
          <div
            className="absolute top-0 left-0 w-full h-[640px] bg-cover bg-center"
            style={{ backgroundImage: `url(${pacifico})` }}
          />
          {/* Main Content */}
          <div className="w-full relative z-10 flex-col items-left mt-10 p-4 pl-16">
            <AppImg src={logoAune} alt="Logo" className="w-1/3" />
            <div className="w-full flex flex-col items-left ">
              <AppText
                title="Sistema de Monitoreo y Gestión de conocimiento"
                type="secondary"
                className="text-3xl text-white font-bold"
              />
              {/* <AppText
                title="adipiscing elit. Aenean commodo"
                type="secondary"
                className="text-xl text-white  "
              />
              <AppText
                title="ligula eget dolor. Aenean massa."
                type="secondary"
                className="text-xl text-white  "
              /> */}
            </div>

            <Button
              onClick={onEnterPlatform}
              className="flex bg-[#4CACBC] shadow-lg h-[35px] rounded-6 min-w-28 text-white font-normal text-lg text-center mt-4  hover:bg-[#3B9AA8] hover:shadow-xl border-none"
            >
              Ingresar a la plataforma
            </Button>
          </div>
          {/* Platform Description */}
          <div className="w-full flex justify-center mt-10">
            <div className="relative z-10 rounded-lg w-3/4 bg-[#000] flex flex-col bg-opacity-70 p-4 items-center text-center ">
              <AppText
                title="La plataforma AUNE"
                type="secondary"
                className="text-white text-2xl max-w-2xl mx-auto"
              />
              <AppText
                title="El pacífico Nariñense vive fenómenos de violencia que influyen en las características sociales de comunidades e individuos. Visibilizando la relación entre las características sociales, de violencia y la salud, se puede tomar acciones para gestionar integralmente los fenómenos de salud."
                type="secondary"
                className="text-white max-w-2xl mx-auto "
              />
            </div>
          </div>
        </div>

        {/* Platform Section */}
        <div className="relative z-10 w-full p-8 text-center bg-[#ebebeb]">
          <h2 className="text-3xl  text-[#4CACBC] font-bold mb-6">
            La plataforma AUNE
          </h2>
          <p className="text-[#000] max-w-2xl mx-auto mb-4">
          AUNE surge como una plataforma que permite sistematizar el conocimiento de esta problemática con impacto a corto y largo plazo, que le brinda a sus usuarios la posibilidad de compartir datos de gran importancia que aporten a la construcción de una solución al problema de la violencia en el Pacífico Nariñense.
          </p>
          <div className="flex justify-center mb-6">
            <div className="w-full max-w-xl">
              {/* Add platform image */}
              <AppImg
                src={plataforma}
                alt="Platform Image"
                className=" top-0 left-0 w-full h-full bg-cover bg-center"
              />
            </div>
          </div>
          <AppButton
            title="Crear cuenta"
            className="bg-aquamarineBlue mx-auto"
            onClick={onRedirectRegister}
          />
        </div>

        {/* User Roles Section */}
        <div
          className="relative z-10 w-full p-8 text-center bg-opacity-70 bg-cover bg-center"
          style={{ backgroundImage: `url(${pacifico})` }}
        >
          <div className="flex flex-col md:flex-row min-h-[500px] justify-center items-center space-y-4 md:space-y-0 md:space-x-20">
            <div className="flex flex-col md:w-1/4 md:min-h-[339px] md:max-h-[413px] items-center bg-white rounded-custom p-6 shadow-lg">
              <div className="flex justify-center items-center relative -top-10 w-20 h-20 min-h-20 rounded-full border-4 bg-white border-white shadow-md">
                <AppImg
                  src={user}
                  alt="Funcionario Image"
                  className="w-2/3 bg-[#fff] object-cover"
                />
              </div>
              <div className="pt-auto flex flex-col flex-1 justify-between">
                <div>
                  <h3 className="text-xl font-bold mb-2 text-[#4CACBC]">
                    Como funcionario de gobierno
                  </h3>
                  <p className="text-gray-700 mb-4 text-black_custom">
                  se pueden realizar comparativas de datos que faciliten la toma decisiones
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col md:w-1/4 md:min-h-[339px] md:max-h-[413px] items-center bg-white rounded-custom p-6 shadow-lg">
              <div className="flex justify-center items-center relative -top-10 w-20 h-20 rounded-full border-4 bg-white border-white shadow-md">
                <AppImg
                  src={lupa}
                  alt="Investigador Image"
                  className="w-2/3 bg-[#fff] object-cover"
                />
              </div>
              <div className="pt-auto flex flex-col flex-1 justify-between">
                <div>
                  <h3 className="text-xl font-bold mb-2 text-[#4CACBC]">
                    Como investigador
                  </h3>
                  <p className="text-gray-700 mb-4  text-black_custom">
                  es posible gestionar archivos y publicar contenido soportado en datos.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex w-full justify-center mt-5 md:-mt-10">
            <AppButton title="Ir a la plataforma" className="bg-lightBlue2" onClick={onEnterPlatform}/>
          </div>
        </div>

        {/* Context Section */}
        <div className=" relative flex flex-col md:flex-row z-10 w-full p-8 text-center">
          {/* Add context image */}
          <AppImg
            src={mapaCol}
            alt="Context Image"
            className="w-1/3 min-w-[360px] self-center"
          />
          <div className="flex flex-col justify-center text-center md:text-left ml-10">
            <h2 className="text-3xl font-bold text-[#4CACBC] mb-6">Contexto</h2>
            <p className="text-black_custom max-w-2xl mb-6">
              El proyecto parte de la hipótesis de que la violencia está
              teniendo consecuencias negativas y cada vez más graves en la salud
              de la población del pacífico nariñense y esto porque{" "}
              <strong>
                con la violencia se está negando el derecho a la salud
              </strong>
              , lo que lleva a la afectación de la salud física y mental de las
              poblaciones.
            </p>
            <div>
              <p className="text-black_custom max-w-2xl mx-auto mb-6">
                Las consecuencias se distribuyen de manera desigual sobre los
                distintos sectores de población y en las diferentes subregiones
                de la costa pacífica nariñense. En función de la intensidad y de
                las diferentes modalidades del conflicto.
              </p>
            </div>

            <div>
              <p className="bg-[#F7C04A] rounded-lg text-[#000] max-w-2xl p-3 shadow">
                <strong>El bienestar de la región pacífica</strong> requiere que
                el Estado proteja los derechos humanos y para ello requiere
                contar con herramientas que permitan analizar las causas
                estructurales e intermedias que determinan la salud y sus
                efectos por cuenta de la violencia.
              </p>
            </div>
          </div>
        </div>

        {/* <Footer /> */}
      </section>
    </>
  );
}
