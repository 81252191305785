/* eslint-disable arrow-body-style */
// import { useState } from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { message } from "antd";
import UpdateTemplate from "../../components/templates/UserTemplate/UpdateTemplate";
import { useAuth } from "../../contexts/AuthContext";
import auth from "../../api/auth";
import user from "../../api/user";
import useApi from "../../api/useApi";
import AppModal from "../../components/molecules/Modal/Modal";
import AppText from "../../components/atoms/Text/AppText";
import ChangePasswordForm from "../../components/organisms/ChangePassword/ChangePasswordForm";
import ReCaptchaForm from "../../components/molecules/FormReCaptcha/ReCaptchaForm";
import sanitizeFileName from "../../helpers/sanitizeFileName";


const optionsGender = [
  {
    id: 1,
    value: "F",
    label: "Femenino ",
  },
  {
    id: 2,
    value: "M",
    label: "Masculino",
  },
  {
    id: 3,
    value: "NoInfo",
    label: "Prefiero no decirlo ",
  },
];
const UpdateUserPage = () => {
  const { currentUser, logout } = useAuth();
  const [dataUser, setDataUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingChangePsw, setLoadingChangePsw] = useState(false);
  const sendCode = useApi(auth.recoveryPswAuth);
  const changePsw = useApi(auth.changePswAuth);
  const getUserInfo = useApi(user.getInfoUser);
  const updateUser = useApi(user.updateUser);
  const uploadPhoto = useApi(user.uploadImage);
  const [modalActive, setModalActive] = useState(false);
  const [captchaConfirmed, setCaptchaConfirmed] = useState(false);
  const [actionModal, setActionModal] = useState("");
  const [file, setFile] = useState(null);
  const [loadingInfo, setLoadingInfo] = useState(false);
  const navigate = useNavigate();
  const { setMessage } = useAuth();

  
  const closeModal = () => {
    setModalActive(false);
  };

  const openModal = () => {
    setModalActive(true);
  };

  const handleDelete = () => {
    closeModal();
    
    updateUser.request(dataUser.id,{status: "Suspendido", isDeleted: true});
    setMessage({
      type: "success",
      title: "Resultado",
      description: "El usuario fue eliminado correctamente.",
    });
    logout();

  };


  useEffect(() => {
    if (currentUser) getUserInfo.request(currentUser?.sub);
    setLoadingInfo(true);
  }, [currentUser]);

  useEffect(() => {
    if (getUserInfo.data) {
      let auxInfo = getUserInfo.data?.data;
      const { gender } = auxInfo;
      let keyGender;
      if (gender === "Masculino") {
        keyGender = "M";
      } else if (gender === "Femenino") {
        keyGender = "F";
      } else {
        keyGender = "NoInfo";
      }
      auxInfo = { ...auxInfo, gender: keyGender };
      setDataUser(auxInfo);
      getUserInfo.setData();
      setLoadingInfo(false);
    }
    if (sendCode.data) {
      setLoading(false);
      setCaptchaConfirmed(true);
      setMessage({
        type: "success",
        title: "Resultado",
        description: "El código ha sido enviado correctamente a su correo.",
      });
      sendCode.setData();
    }
    if (changePsw.data) {
      setMessage({
        type: "success",
        title: "Resultado",
        description: "La contraseña fue cambiada correctamente.",
      });
      setLoadingChangePsw(false);
      closeModal();
    }
    if (updateUser.data) {
      const auxData = updateUser.data.user;
      if (file) {
        const { name, size } = file;
        const fileExtension = `.${name.split(".").pop()}`;
        const safeName = sanitizeFileName(name);
        const formaData = new FormData();
        formaData.append("image", file.originFileObj, safeName);
        uploadPhoto.request(
          formaData,
          safeName,
          fileExtension,
          size
        );
          
      }else{
        // setLoadingInfo(false);
        setMessage({
          type: "success",
          title: "Resultado",
          description: "La información fue actualizada correctamente.",
        });
        getUserInfo.request(auxData.email);
      }
      // setLoadingInfo(false);

      updateUser.setData();
    }
    if (uploadPhoto.data) {
      setLoadingInfo(false);
      const auxData = uploadPhoto.data.user;
      setMessage({
        type: "success",
        title: "Resultado",
        description: "La información fue actualizada correctamente.",
      });
      uploadPhoto.setData();
      getUserInfo.request(auxData.email);
    }
  }, [
    getUserInfo.data, 
    sendCode.data, 
    changePsw.data, 
    updateUser.data, 
    uploadPhoto.data]);

  useEffect(() => {
    if (sendCode.error) {
      setLoading(false);
      setCaptchaConfirmed(false);
      setModalActive(false);
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar enviar el código. Vuelva a intentarlo.",
        code: sendCode.error,
      });
    }
    if (changePsw.error) {
      setLoadingChangePsw(false);
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar cambiar la contraseña. Vuelva a intentarlo.",
        code: changePsw.error,
      });
    }
    if (updateUser.error) {
      setLoadingInfo(false);
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar actualizar la información. Vuelva a intentarlo.",
        code: updateUser.error,
      });
    }
    if (uploadPhoto.error) {
      setMessage({
        type: "error",
        title: "Error",
        description: "Hubo un error al intentar subir la imagen. Vuelva a intentarlo.",
        code: uploadPhoto.error,
      });
    }
  }, [sendCode.error, changePsw.error, updateUser.error, uploadPhoto.error]);

  const handleEdit = (data) => {
    // const auxDate = data;
    const { photo, avatar, gender, phone,...rest } = data;
    setFile(photo);
    let auxGender;
    switch (gender){
      case "F":
        auxGender = "Femenino";
        break;
      case "M":
        auxGender = "Masculino";
        break;
      default:
        auxGender = "NoInfo";
    }
    

    updateUser.request({...rest,gender: auxGender, phone: phone.toString()});
    setLoadingInfo(true);

    // message.success("Datos actualizados correctamente");
  };

  const onDelete = () => {    
    setActionModal("delete");
    openModal();
  };

  const onChangePassword = () => {
    setCaptchaConfirmed(false);
    setActionModal("changePassword");
    openModal();
  };

  const handlerChangePassword = (data) => {
    if (data) {
      const { code, confirm } = data;
      setLoadingChangePsw(true);
      changePsw.request({ code, newPassword: confirm });
    }
  };

  const goHome = () => {
    navigate("/");
  };

  const onHandleCaptcha = (value) => {
    if (value) {
      setLoading(true);
      sendCode.request();
    } else {
      setCaptchaConfirmed(false);
    }
  };

  const renderModalContent = () => {
    if (actionModal === "delete")
      return <AppText title="¿Está seguro de eliminar esta cuenta?" />;
    if (!captchaConfirmed) {
      return (
        <ReCaptchaForm onHandleCaptcha={onHandleCaptcha} loading={loading} />
      );
    }
    return (
      <ChangePasswordForm
        handlerPassword={handlerChangePassword}
        loading={loadingChangePsw}
      />
    );
  };

  return (
    <>
      <UpdateTemplate
        editData={handleEdit}
        dataUser={dataUser}
        optionsGender={optionsGender}
        onDelete={onDelete}
        onChangePassword={onChangePassword}
        goHome={goHome}
        loading={loadingInfo}

      />
      {modalActive ? (
        <AppModal
          title={actionModal === "delete" ? "Eliminar Usuario" : null}
          open={modalActive}
          onCancel={closeModal}
          // eslint-disable-next-line react/jsx-boolean-value
          showFooter={actionModal === "delete"}
          className=" text-lightBlue2 font-bold text-lg text-center !important"
          onOk={() => handleDelete()}
          ok="Eliminar"
          cancel="Cancelar"
          okType="danger"
        // icon={<ExclamationCircleFilled />}
        >
          {renderModalContent()}
        </AppModal>
      ) : null}
    </>
  );
};
export default UpdateUserPage;
