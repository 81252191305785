/* eslint-disable no-underscore-dangle */
/* eslint-disable arrow-body-style */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import user from "../../api/user";
import useApi from "../../api/useApi";
import UserRegisterTemplate from "../../components/templates/UserTemplate/RegisterTemplate";
import { useAuth } from "../../contexts/AuthContext";
// import sanitizeFileName from "../../helpers/sanitizeFileName";
import fetchingFiles from "../../api/fetchingFiles";
import { listCreateUserRolesGeneral } from "../../constant/listCreateUserRoles";

// import "../../assets/css/styleSelect.css";
const optionsGender = [
  {
    id: 1,
    value: "F",
    label: "Femenino ",
  },
  {
    id: 2,
    value: "M",
    label: "Masculino",
  },
  {
    id: 3,
    value: "NoInfo",
    label: "Prefiero no decirlo ",
  },
];

const UserCreatePage = () => {
  const createRequest = useApi(user.createUser);
  const getUrlPresigned = useApi(user.getUrlPresigned);
  // const uploadPdf = useApi(user.uploadPdf);
  const [files, setFiles] = useState([]);
  const { setMessage } = useAuth();
  const navigate = useNavigate();
  // const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  const uploadImages = async (arrUrl) => {
    const results = await fetchingFiles(arrUrl);
    const failed = results.every((val) => !val);
    if (failed) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Error al subir los documentos del usuario en el sistema. Por favor comuníquese con un administrador.",
      });
    }

    navigate("/login");
  };

  useEffect(() => {
    if (createRequest.data) {
      setMessage({
        type: "info",
        title: "Registro pendiente",
        description:
          "Usuario creado correctamente. Se le enviará un correo en cuanto se apruebe el registro.",
      });
      setFiles();
      /*  const auxData = createRequest.data.data;
      const id = auxData.id || auxData._id;
      if (files) {
        const [dni, entityCard] = files;
        const nameDni = dni?.name;
        const nameEnt = entityCard?.name;
        let auxDniExt = "";
        if (nameDni) auxDniExt = nameDni.split(".").pop();
        let auxEntExt = "";
        if (nameEnt) auxEntExt = nameEnt.split(".").pop();
        getUrlPresigned.request(id, auxDniExt, auxEntExt);
      } else {
        setMessage({
          type: "success",
          title: "Usuario creado",
          description: "Usuario creado correctamente.",
        });
      } */
      setLoading(false);
      createRequest.setData();
      navigate("/login");
    }
    if (getUrlPresigned.data) {
      const auxData = getUrlPresigned.data.data;
      const { presignedUrlIdCard, presignedUrlIdEntity } = auxData;
      let arrUrl = [];
      if (presignedUrlIdCard && presignedUrlIdEntity) {
        arrUrl = [
          { url: presignedUrlIdCard, file: files[0] },
          { url: presignedUrlIdEntity, file: files[1] },
        ];
      } else if (presignedUrlIdCard) {
        arrUrl = { url: presignedUrlIdCard, file: files[0] };
      } else {
        arrUrl = { url: presignedUrlIdEntity, file: files[1] };
      }
      uploadImages(arrUrl);
      getUrlPresigned.setData();
    }
  }, [createRequest.data, getUrlPresigned.data]);

  useEffect(() => {
    if (createRequest.error) {
      if (createRequest.error === "User already exists") {
        setMessage({
          type: "error",
          title: "Error",
          description:
            "El usuario con ese correo ya se encuentra registrado en el sistema. Por favor, inicie sesión.",
        });
      } else {
        setLoading(false);
        setMessage({
          type: "error",
          title: "Error",
          description:
            "Error al crear el usuario en el sistema. Por favor, intente nuevamente.",
        });
      }
      setLoading(false);
    }
    if (getUrlPresigned.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Error al subir los documentos del usuario en el sistema. Por favor comuníquese con un administrador.",
      });
    }
  }, [createRequest.error, getUrlPresigned.error]);

  const handleSave = (values) => {
    setLoading(true);
    const { phone, gender /* , entityCard, dni */, confirmPassword, ...rest } =
      values;
    let auxGender;
    switch (gender) {
      case "F":
        auxGender = "Femenino";
        break;
      case "M":
        auxGender = "Masculino";
        break;
      default:
        auxGender = "NoInfo";
    }
    const userData = {
      ...rest,
      gender: auxGender,
      phone: phone.toString(),
    };
    createRequest.request(userData);
    // setFiles([dni, entityCard]);
  };

  return (
    <UserRegisterTemplate
      saveData={handleSave}
      loading={loading}
      roles={listCreateUserRolesGeneral}
      optionsGender={optionsGender}
    />
  );
};

export default UserCreatePage;
